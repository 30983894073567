<template>
    <div class="edit-customer">
        <h1>Edit Customer</h1>

        <div v-if="modalContent.name.includes('PaymentDate')">
            <h2>Paid</h2>

            <label>Payed at: </label>
            <input type="date" :min="new Date()" v-model="payment.paidAt">

            <button @click="handleUpdate()">
                Customer have paid
            </button>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import humanizeTimeStamp from '@/filters/humanize_time_stamp'

export default {
    name: 'EditCustommer',
    filters: {
        humanizeTimeStamp,
    },
    data() {
        return {
            payment: {
                paidAt: null
            },
        }
    },
    computed: {
        ...mapState(['modalContent']),
    },
    methods: {
        ...mapActions('admin', ['updateCustomerPayDate']),
        handleUpdate() {
            let paidAt = + new Date(this.payment.paidAt)

            this.updateCustomerPayDate({
                id: this.modalContent.id,
                name: this.modalContent.name,
                paidAt: humanizeTimeStamp(paidAt),
            })
        },
    },
}
</script>

<style lang="scss">
.edit-customer {

}
</style>
