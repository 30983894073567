var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin"},[_c('h1',[_vm._v("Hej ADMIN")]),_c('table',[_vm._m(0),_vm._l((_vm.customers),function(item){return _c('tr',{key:item.companyName},[_c('th',{domProps:{"innerHTML":_vm._s(item.companyName)}}),(item)?_c('th',{domProps:{"innerHTML":_vm._s(item.offer.driveLink)}}):_vm._e(),_c('th',{domProps:{"innerHTML":_vm._s(item.contract.driveLink)}}),_c('th',{domProps:{"innerHTML":_vm._s(item.contract.createdAtFormatted)}}),_c('th',{domProps:{"innerHTML":_vm._s(item.currency)}}),_c('th',{staticClass:"payed clickable",class:{
                    'payed--true': item.firstPayment.paid,
                    'payed--false': !item.firstPayment.paid && new Date(item.firstPayment.payDate) < new Date()
                },on:{"click":function($event){return _vm.handleModal({
                    id: item.id,
                    name: 'adminFirstPaymentDate',
                    data: item.firstPayment
                })}}},[_vm._v(" "+_vm._s(item.firstPayment.payDate)+" ")]),_c('th',[_vm._v(" "+_vm._s(item.firstPayment.amount)+" ")]),_c('th',{staticClass:"payed clickable",class:{
                    'payed--true': item.secondPayment.paid,
                    'payed--false':
                        !item.secondPayment.paid &&
                        new Date(item.secondPayment.payDate) < new Date() &&
                        item.secondPayment.payDate !== null
                },on:{"click":function($event){return _vm.handleModal({
                    id: item.id,
                    name: 'adminSecondPaymentDate',
                    data: item.secondPayment
                })}}},[_vm._v(" "+_vm._s(item.secondPayment.payDate)+" ")]),_c('th',[_vm._v(" "+_vm._s(item.secondPayment.amount)+" ")]),_c('th',{domProps:{"innerHTML":_vm._s(item.country)}}),_c('th',{domProps:{"innerHTML":_vm._s(item.name)}}),_c('th',{domProps:{"innerHTML":_vm._s(item.email)}}),_c('th',{domProps:{"innerHTML":_vm._s(item.phoneNumber)}}),_c('th',{domProps:{"innerHTML":_vm._s(item.streetZipCity)}}),_c('th',{domProps:{"innerHTML":_vm._s(item.websiteName)}})])})],2),_c('add-customer'),(_vm.showModal)?_c('modal',{attrs:{"content":_vm.modalContent}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("Business name")]),_c('th',[_vm._v("Offer")]),_c('th',[_vm._v("Contract")]),_c('th',[_vm._v("Contract date")]),_c('th',[_vm._v("Currency")]),_c('th',[_vm._v("1st. payment date")]),_c('th',[_vm._v("1st. payment amount")]),_c('th',[_vm._v("2nd. payment date")]),_c('th',[_vm._v("2nd payment amount")]),_c('th',[_vm._v("Country")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Email")]),_c('th',[_vm._v("Phone number")]),_c('th',[_vm._v("Street/zip/city")]),_c('th',[_vm._v("Website name")])])}]

export { render, staticRenderFns }