<template>
    <div class="admin">
        <h1>Hej ADMIN</h1>

        <table>
            <tr>
                <th>Business name</th>
                <th>Offer</th>
                <th>Contract</th>
                <th>Contract date</th>
                <th>Currency</th>
                <th>1st. payment date</th>
                <th>1st. payment amount</th>
                <th>2nd. payment date</th>
                <th>2nd payment amount</th>
                <th>Country</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone number</th>
                <th>Street/zip/city</th>
                <th>Website name</th>
            </tr>

             <tr
                v-for="item in customers"
                :key="item.companyName"
             >
                <th v-html="item.companyName"></th>

                <th v-if="item" v-html="item.offer.driveLink"></th>

                <th v-html="item.contract.driveLink"></th>

                <th v-html="item.contract.createdAtFormatted"></th>

                <th v-html="item.currency"></th>

                <th
                    @click="handleModal({
                        id: item.id,
                        name: 'adminFirstPaymentDate',
                        data: item.firstPayment
                    })"
                    class="payed clickable"
                    :class="{
                        'payed--true': item.firstPayment.paid,
                        'payed--false': !item.firstPayment.paid && new Date(item.firstPayment.payDate) < new Date()
                    }"
                >
                    {{
                        item.firstPayment.payDate
                    }}
                </th>

                <th>
                    {{
                        item.firstPayment.amount
                    }}
                </th>

                <th
                    @click="handleModal({
                        id: item.id,
                        name: 'adminSecondPaymentDate',
                        data: item.secondPayment
                    })"
                    class="payed clickable"
                    :class="{
                        'payed--true': item.secondPayment.paid,
                        'payed--false':
                            !item.secondPayment.paid &&
                            new Date(item.secondPayment.payDate) < new Date() &&
                            item.secondPayment.payDate !== null
                    }"
                >
                    {{
                        item.secondPayment.payDate
                    }}
                </th>

                <th>
                    {{
                        item.secondPayment.amount
                    }}
                </th>

                <th v-html="item.country"></th>

                <th v-html="item.name"></th>

                <th v-html="item.email"></th>

                <th v-html="item.phoneNumber"></th>

                <th v-html="item.streetZipCity"></th>

                <th v-html="item.websiteName"></th>
            </tr>
        </table>

        <add-customer />

        <modal v-if="showModal" :content="modalContent" />
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import AddCustomer from '@/components/admin/AddCustomer'
import Modal from '@/components/Modal'

export default {
    name: 'Admin',
    components: {
        AddCustomer,
        Modal,
    },
    computed: {
        ...mapState(['customers', 'showModal', 'modalContent']),
    },
    methods: {
        ...mapActions(['getCustomers', 'toggleModal']),
        ...mapMutations(['setModalContent']),
        handleModal(content) {
            this.setModalContent(content)

            this.toggleModal()
        },
    },
    mounted() {
        this.getCustomers()
    },
}
</script>

<style lang="scss">
.admin {
    table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
        border-collapse: collapse;

        tr {
            &:nth-child(odd) {
                background-color: #eff6ff;
            }

            &:nth-child(even) {
                background-color: white;
            }

            &:first-child {
                background-color: #bbd6ff;

                th {
                    text-align: center;
                }
            }
        }

        th {
            padding: 8px;
            border: 1px solid #707070;
            text-align: left;
        }

        .payed {
            background-color: orange;

            &--true {
                background-color: green;
            }

            &--false {
                background-color: red;
            }
        }
    }

    .clickable {
        cursor: pointer;
    }
}
</style>
