<template>
    <div class="modal">
        <div @click="closeModal" class="modal__backdrop"></div>

        <div class="modal__content">
            <edit-customer v-if="modalContent.name.includes('admin')" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import EditCustomer from '@/components/admin/EditCustomer'

export default {
    name: 'Modal',
    components: {
        EditCustomer,
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapState(['showModal', 'modalContent']),
    },
    methods: {
        ...mapActions(['toggleModal']),
        closeModal(event) {
            if (event.target.className.includes('backdrop')) {
                this.toggleModal(false)

                document.body.style.overflow = 'visible'
            }
        },
    },
    mounted() {
        document.body.style.overflow = 'hidden'
    },
}
</script>

<style lang="scss">
.modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    &__backdrop {
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, .7);
    }

    &__content {
        position: relative;
        z-index: 20;
        top: -35%;
        left: 50%;
        width: 250px;
        padding: 18px 12px;
        transform: translate(-50%, -50%);
        border-radius: 12px;
        background-color: white;
    }
}
</style>
