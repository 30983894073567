<template>
    <div class="add-customer">
        <h1>Add Customer</h1>

        <form
            @submit.prevent="createCustomer"
            class="form"
        >
            <div class="form__group">
                <label>Company Name</label>

                <input
                    type="text"
                    v-model="customer.companyName"
                    placeholder="CompanyName"
                    class="form__input"
                />
            </div>

            <div class="form__group">
                <label>Offer link</label>

                <input
                    type="text"
                    v-model="customer.offer.driveLink"
                    placeholder="Offer link to drive"
                    class="form__input"
                />
            </div>

            <div class="form__group">
                <label>Offer date</label>

                <input
                    type="date"
                    :min="new Date()"
                    v-model="customer.offer.createdAt"
                    placeholder="Offer date"
                    class="form__input"
                />
            </div>

            <div class="form__group">
                <label>Offer amount</label>

                <input
                    type="number"
                    v-model="customer.offer.amount"
                    placeholder="Offer amount"
                    class="form__input"
                />
            </div>

            <div class="form__group">
                <label>Contract link</label>

                <input
                    type="text"
                    v-model="customer.contract.driveLink"
                    placeholder="Contact link"
                    class="form__input"
                />
            </div>

            <div class="form__group">
                <label>Contract date</label>

                <input
                    type="date"
                    :min="new Date()"
                    v-model="customer.contract.createdAt"
                    placeholder="Contract date"
                    class="form__input"
                />
            </div>

            <div class="form__group">
                <label>Currency</label>

                <input
                    type="text"
                    v-model="customer.currency"
                    placeholder="Currency"
                    class="form__input"
                />
            </div>

            <div class="form__group">
                <label>First payment date</label>

                <input
                    type="date"
                    :min="new Date()"
                    v-model="customer.firstPayment.payDate"
                    placeholder="First payment date"
                    class="form__input"
                />
            </div>

            <div class="form__group">
                <label>Country</label>

                <input
                    type="text"
                    v-model="customer.country"
                    placeholder="Country"
                    class="form__input"
                />
            </div>

            <div class="form__group">
                <label>Email</label>

                <input
                    type="email"
                    v-model="customer.email"
                    placeholder="Email"
                    class="form__input"
                />
            </div>

            <div class="form__group">
                <label>Name</label>

                <input
                    type="text"
                    v-model="customer.name"
                    placeholder="Name"
                    class="form__input"
                />
            </div>

            <div class="form__group">
                <label>Phone number</label>

                <input
                    type="tel"
                    v-model="customer.phoneNumber"
                    placeholder="Phone number"
                    class="form__input"
                />
            </div>

            <div class="form__group">
                <label>Street, zip & city</label>

                <input
                    type="text"
                    v-model="customer.streetZipCity"
                    placeholder="Street, zip & city"
                    class="form__input"
                />
            </div>

            <div class="form__group">
                <label>Website name</label>

                <input
                    type="text"
                    v-model="customer.websiteName"
                    placeholder="Website name"
                    class="form__input"
                />
            </div>

            <button type="submit">
                Add customer
            </button>
        </form>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import humanizeTimeStamp from '@/filters/humanize_time_stamp'
import createFirebaseId from '@/filters/create_firebase_id'

export default {
    name: 'AddCustommer',
    filters: {
        humanizeTimeStamp,
        createFirebaseId,
    },
    data() {
        return {
            customer: {
                id: null,
                companyName: null,
                offer: {
                    amount: null,
                    createdAt: null,
                    createdAtFormatted: null,
                    driveLink: 'www.google.com',
                },
                contract: {
                    createdAt: null,
                    createdAtFormatted: null,
                    driveLink: 'www.google.com',
                },
                firstPayment: {
                    amount: null,
                    paid: false,
                    paidAt: null,
                    payDate: null,
                },
                secondPayment: {
                    amount: null,
                    paid: false,
                    paidAt: null,
                    payDate: null,
                },
                country: 'BG', // TODO: Remove hardcode when multiple countries
                currency: 'BGN', // TODO: Remove hardcode when multiple currencies
                email: null,
                phoneNumber: null,
                streetZipCity: null,
                websiteName: null,
            }
        }
    },
    methods: {
        ...mapActions('admin', ['addCustomerToDB']),
        calculatePaymentAmounts(customer) {
            customer.firstPayment.amount = customer.offer.amount * .3
            customer.secondPayment.amount = customer.offer.amount * .7
        },
        createDriveLink(link) {
            return `<a href='${link}' rel='noopener' target='_blank'>Drive link</a>`
        },
        createCustomer() {
            this.calculatePaymentAmounts(this.customer)

            // Need to cast numbers
            this.customer.offer.amount = Number(this.customer.offer.amount)
            this.customer.firstPayment.amount = Number(this.customer.firstPayment.amount)
            this.customer.secondPayment.amount = Number(this.customer.secondPayment.amount)

            // Create drive links
            this.customer.offer.driveLink = this.createDriveLink(this.customer.offer.driveLink)
            this.customer.contract.driveLink = this.createDriveLink(this.customer.contract.driveLink)

            // Offer date - unix
            this.customer.offer.createdAt = + new Date(this.customer.offer.createdAt)
            this.customer.offer.createdAtFormatted = humanizeTimeStamp(this.customer.offer.createdAt)

            // Contract date - unix
            this.customer.contract.createdAt = + new Date(this.customer.contract.createdAt)
            this.customer.contract.createdAtFormatted = humanizeTimeStamp(this.customer.contract.createdAt)

            // Format paydate
            this.customer.firstPayment.payDate = humanizeTimeStamp(this.customer.firstPayment.payDate)

            // Create id
            this.customer.id = createFirebaseId(this.customer.companyName)

            this.addCustomerToDB(this.customer)
        },
    },
}
</script>

<style lang="scss">
.add-customer {
    .form {
        display: flex;
        position: relative;
        padding: 18px;
        flex-direction: column;
        border-radius: 6px;
        background-color: white;
        box-shadow: #0e77ff26 0 10px 30px;

        &__group {
            display: flex;
            position: relative;
            flex-direction: column;
        }

        &__input {
            position: relative;
            margin-bottom: 12px;
            border: none;
            border-bottom: 1px solid black;
            outline: none;
        }
    }
}
</style>
